.content__uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content__uploader input {
    background: transparent;
    border: 1px solid black;
}

.content__uploader fieldset {
    height: 200px;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}