.birthday__wrapper {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 24px;
}

.birthday__gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}

.birthday__gallery_container{
    max-width: 30%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.green__btn.ctn {
    text-align: center;
}

.first__pic {
    max-width: 50%;
}

.gallery__btns {
    display: flex;
    justify-content: space-between;
}

.gallery__btns button {
    background-color: transparent;
    border: 1px solid black;
    padding: 4px;
    cursor: pointer;
    width: 100px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
    .birthday__wrapper {
        gap: 16px;
    }

    .birthday__gallery {
        max-width: 90%;
        aspect-ratio: 1/1;
        justify-content: center;
        justify-self: center;
    }
    
}

@media (max-width: 992px) { 

   }
  @media (max-width: 768px) { 

   }
  
  @media (max-width: 576px) { 

  }
  
  @media (max-width: 280px) { 

    .birthday__wrapper {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
  }