h1 {
    font-family: 'Parisienne', cursive;
}

h2,h3 {
    font-family: 'Marck Script', cursive;
    text-align: center;

}

.home__title {
    text-align: center;
    color: var(--clr-brown);
    text-shadow: 2px 4px 2px rgba(0,0,0,0.6);
}

.container .article {
    display: flex;
    flex-direction: column;
}

.article__left {
    display: flex;
    flex-direction: row;
}

.article__left h2  {
    text-align: center;
}

.article__left:nth-last-child(1) {
    margin-left: 100px;
}

.article__left:nth-last-child(1) h3 {
    font-weight: 200;
    font-family: 'Marck Script', cursive;
}

.article__left:nth-last-child(1) img {
    transform: rotate(10deg);
    margin-bottom: 2rem;
    transition: var(--trans) ;

}

.article__left:nth-last-child(1) img:hover {
    transform: rotate(0deg);
}

.article__header {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem;
}

.article__header h2 {
    font-weight: 200;
}

.article__header h3 {
    font-weight: 100;
}

.article__right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

}

.article__logo {
    width: 100%;
    aspect-ratio: 1/1;
    max-width: 35%;
    border-radius: 1rem;
    transition: var(--trans) ;
}

/* Pic from left */
.article__logo.left {
    margin-top: 2rem;
    transform: rotate(1deg);
}

.article__logo.right {
    transform: rotate(-1deg);
    margin-bottom: 2rem;
}

.article__logo.left:hover,
.article__logo.right:hover {
    transform: rotate(0);
}

.slide-in-left {
    animation: slide-in-left 0.5s ease-out;
}

.slide-in-right{
    animation: slide-in-right 0.5s ease-out;
}

.slide-in-bottom {
    animation: slide-in-bottom 0.5s ease-out;
}

@keyframes slide-in-left {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
}

@keyframes slide-in-right {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slide-in-bottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

@media only screen and (max-width: 1200px) {

    .article__left,
    .article__right {
        justify-content: center;
    }

    .home__title {
        font-size: 3.5rem;
    }

    .article__header h2 {
        font-size: 3rem;
      }
      
    .article__header h3 {
        font-size: 2rem;
      }
    .article__logo {
        height: 90%;
        max-width: 30%;
    }
    .article__right img {
        margin-right: 40px;
    }
    .article__logo {
        max-width: 40%;
        margin-left: 20px;
    }
}

@media only screen and (max-width: 992px) {

}

@media only screen and (max-width: 768px) {
    .home__title {
        font-size: 3rem;
    }

    .article__header h2 {
        font-size: 2.9rem;
      }
      
    .article__header h3 {
        font-size: 2rem;
      }

      .article__right img {
        margin-right: 40px;
    }
    .article__logo {
        max-width: 40%;
        margin-left: 20px;
    }

    .article__left:nth-last-child(1)  {
        margin: 0;
        padding: 10px;
    }

      .article__left:nth-last-child(1) img {
        max-width: 40%;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 576px) {
    .home__title {
        font-size: 1.5rem;
    }

    .article__header h2 {
        font-size: 1.5rem;
      }
      
    .article__header h3 {
        font-size: 1rem;
      }

      .article__left:nth-last-child(1)  {
        margin: 0;
        padding: 10px;
    }

      .article__left:nth-last-child(1) img {
        max-width: 40%;
    }

}