.list__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.list__card {
    background: var(--trans);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.list__card img {
    max-width: 100%;
    aspect-ratio: 1/1;
}

.list__title {
    padding: 1rem;
    text-transform: uppercase;
    text-align: center;
}

@media only screen and (max-width: 1200px) {

    .list__title {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 992px) {
    .list__card {
        max-width: 100%;
        padding: 0;
    }

    .list__title {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 768px) {
    .list__cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .list__card img {
        max-width: 50%;
    }

    .list__title {
        font-size: 1.2rem;
    }

    .list__card .green__btn {
        padding: 0.5rem 3rem;

    }
}

@media only screen and (max-width: 600px) {
    .list__cards {
        grid-template-columns: repeat(1, 1fr);
    }

    .list__cards img {
        max-width: 40%;
    }

    .list__title {
        font-size: 1.2rem;
    }
}
